const notAvailable = '–'

function isValidNumber(v: any) {
  return !isNaN(v) && v != null && v !== ''
}

// 匹配任何形式的 "0"，不限制小數點後的位數，包括 "0.00000"、"-0.00000"、"0.0"、"-0.0" 
function matchZero(value: any) {
  return String(value).match(/^-?0+(\.0+)?$/)
}

// 包裝千分位號、% 
function percentFormat(value: any) {
  return value.toString().replace(/^-([0\.]+)$/, '$1').replace(/\B(?=(\d{3})+\b)/g, ',') + '%'
}

// 取得最小有數值的位數 (數字介於 0~1 之間)
function getMinPrecision(num: number) {
  if (num >= 1 || num == 0) return 0
  return String(num).split('.')[1].split('').findIndex(d => d != '0') + 1
}

export default function useFilter() {

  return {
    percentage(value: any, precision = 0) {
      if (!isValidNumber(value)) {
        return notAvailable
      }
      try {
        let formattedValue = (+value * 100).toFixed(precision);
    
        // 檢查是否有小數部分
        const hasDecimal = formattedValue.indexOf('.') !== -1;
    
        // 如果有小數部分，則處理整數部分
        if (hasDecimal) {
            const parts = formattedValue.split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+\b)/g, ',');
            formattedValue = parts.join('.');
        } else {
            // 沒有小數部分，直接處理整數部分
            formattedValue = formattedValue.replace(/\B(?=(\d{3})+\b)/g, ',');
        }
    
        return formattedValue + '%';
      } catch (e) {
        console.warn(e)
        return notAvailable
      }
    },
    percentageWise(value: string | number, precision = 0) {
      if (!isValidNumber(value)) {
        return notAvailable
      }
      const result = (+value * 100).toFixed(precision)
      // 0.0 或 -0.0 都顯示 0%
      if (matchZero(result)) return '0%'
      try {
        return percentFormat(result)
      } catch (e) {
        console.warn(e)
        return notAvailable
      }
    },
    number(value: any) {
      return parseFloat(value) || 0
    },
    /**
     * 以十進位小數格式來顯示貨幣
     * 
     * @param precision 小數位精度
     * @param keepZero 是否要保留 0 的精度
     * @example
     * 
     *  關於 keepZero 與精度
     *  decimal(1234.5600, 3, false) => 1,234.56 // 小數位無數值部分會被移除
     *  decimal(1234.5600, 3, true) => 1,234.560 // 除非 keepZero 開啟才會補滿精度
     * 
     *  關於負數與 0
     *  decimal(-0.01, 2, true) => -0.01
     *  decimal(-0.01, 1, true) => 0.0 // 如果精度捨去後為 0，一律捨棄負號
     *  decimal(-0.01, 1, false) => 0 // 同上，小數位無數值部分會被移除
     */
    decimal(value: any, precision: number = 0, keepZero: boolean = false) {
      if (!isValidNumber(value)) return notAvailable
      const decimalValue = (+value).toFixed(precision)
      const integerPart = decimalValue.split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      let decimalPart = decimalValue.split('.')[1] || ''
      if (keepZero && precision > 0) {
        // 保留小數末尾的0
        decimalPart = decimalPart.padEnd(precision, '0').slice(0, precision)
      } else {
        decimalPart = decimalPart ? decimalPart.replace(/0+$/, '') : ''
      }
      const combined = decimalPart ? `${integerPart}.${decimalPart}` : integerPart
      const zeroMatch = combined.match(/^-(0\.0+)$/)
      // 如果是 -0.000 (不論幾位數)，一律去掉負號
      if (zeroMatch) {
        return zeroMatch[1]
      }
      return combined
    },
    /**
     *  規則基本上同 decimal 
     *  差在整數 '0.0'、'-0.0' 一律顯示為 0
     * 
     *  decimalTrimZero(0.002 , 2, true) => 0
     *  decimalTrimZero(10 , 2, true) => 10.00
     */
    decimalTrimZero(value: any, precision: number = 0, keepZero: boolean = false) {
      if (!isValidNumber(value)) return notAvailable
      const decimalValue = (+value).toFixed(precision)
      const integerPart = decimalValue.split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      let decimalPart = decimalValue.split('.')[1] || ''
      if (keepZero && precision > 0) {
        // 保留小數末尾的0
        decimalPart = decimalPart.padEnd(precision, '0').slice(0, precision)
      } else {
        decimalPart = decimalPart ? decimalPart.replace(/0+$/, '') : ''
      }
      const combined = decimalPart ? `${integerPart}.${decimalPart}` : integerPart
      if (matchZero(combined)) return 0
      return combined
    },
    round(value: any, digits = 0) {
      if (!isValidNumber(value)) { return notAvailable }
      value = (+value).toFixed(digits)
      value = isValidNumber(value) ? (value) : notAvailable
      if (digits === '~0') { digits = Math.abs(value) < 1 ? 1 : 0 }
      return value
    },
    currency(value: any, digits = 0) {
      if (value == null) { return notAvailable }
      if (digits === '~0') { digits = Math.abs(value) < 1 ? 1 : 0 }
      return (+value).toFixed(digits).split(/\./).map((p, i) => i == 0 ? p.replace(/\B(?=(\d{3})+\b)/g, ',') : p).join('.')
    },
    /**
     * @param value 
     * @param precision 
     * @param keepZero 
     * @rule
     * 
     *  進位：四捨五入
     *  整數結果為 0.0，則顯示 0
     *  小數點第 X 位遇 0，例如第四位遇 0 -> 95.1230
     *  當個位數為 0 且本身不是 0，需要取到有數值為止
     * 
     * @example
     * 
     *  decimalUnlimited(0.00004, 2, true) => 0.00004
     *  decimalUnlimited(-0.000000625 , 2, true) => -0.000001
     */
    decimalUnlimited(value: any, precision: number = 0, keepZero: boolean = false):any {
      if (!isValidNumber(value)) return notAvailable
      const decimalValue = (+value).toFixed(precision)
      const integerPart = decimalValue.split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") // 個位數
      let decimalPart = decimalValue.split('.')[1] || '' // 小數
      const combined = decimalPart ? `${integerPart}.${decimalPart}` : integerPart
      if (+integerPart === 0 && value !== 0) {
        // 取到有數值為止
        return matchZero(combined) ? this.decimalUnlimited(value, precision+1, keepZero) : combined
      } else {
        return this.decimalTrimZero(value, precision, keepZero)
      }
    },
    /**
     * 
     * @rule
     * 
     *  進位：四捨五入
     *  整數結果為 0.0，則顯示 0
     *  小數點第 X 位遇 0，例如第四位遇 0 -> 95.1230
     *  當個位數為 0 且本身不是 0，需要取到有數值為止
     * 
     * @example
     * 
     *  percentageUnlimited(-0.000451797 , 1, true) => -0.05%
     *  percentageUnlimited(0.000214 , 1, true) => 0.02%
     *  percentageUnlimited(71.19449 , 1, true) => 7,119.4%
     */
    percentageUnlimited(value: string | number, precision = 0):any {      
      if (!isValidNumber(value)) return notAvailable
      const decimalValue = (+value * 100).toFixed(precision)
      const integerPart = decimalValue.split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") // 個位數
      let decimalPart = decimalValue.split('.')[1] || '' // 小數
      const combined = decimalPart ? `${integerPart}.${decimalPart}` : integerPart
      if (+integerPart === 0 && value !== 0) {
        // 取到有數值為止
        return matchZero(combined) ? this.percentageUnlimited(value, precision+1) : percentFormat(combined)
      } else {
        return this.percentageWise(value, precision)
      }

    },
    /**
     *  @rule
     * 
     *  進位：四捨五入
     *  整數結果為 0.0，則顯示 0
     * 
     *  @param hideNegative
     *  負值顯示「-」
     * 
     *  @example
     *  sharp(-2.528, 1, true) => '-'
     *  sharp(-2.528, 1, false) => -2.5
     */
    sharp(value: any, precision: number = 0, hideNegative: boolean = false) {  
      if (!isValidNumber(value) || (hideNegative && +value < 0)) return notAvailable
      const result = (+value).toFixed(precision)
      if (matchZero(result)) return 0
      return result
    },
    /**
     *  @rule
     * 
     *  進位：四捨五入
     *  整數結果為 0.0，則顯示 0
     *  當個位數為 0 且本身不是 0，需要取到有數值為止
     * 
     *  @param hideNegative
     *  負值顯示「-」
     * 
     *  @example
     *  sharpUnlimited(-2.528, 1, true) => '-'
     *  sharpUnlimited(-2.528, 1, false) => -2.5
     *  sharpUnlimited(0.047, 1, false)) => 0.05
     *  sharpUnlimited(0.056, 1, false)) => 0.1
     *  sharpUnlimited(0.0029, 1, false)) => 0.003
     */
    sharpUnlimited(value: any, precision: number = 0, hideNegative: boolean = false): string {
      if (!isValidNumber(value) || (hideNegative && +value < 0)) return notAvailable
      if (matchZero(value)) return '0'
      const minPrecision = Math.max(getMinPrecision(value), precision)
      const result = (+value).toFixed(precision)
      if (minPrecision !== 0 && matchZero(result)) return this.sharpUnlimited(value, precision + 1, hideNegative)
      return result
    },
  }
}
